import { IFetchLocationPackagesParams } from "@/hooks/esim/location-packages/types/locationPackage.types";
import { IApiResponse } from "@/api/interfaces/IApi";

export async function fetchLocationPackages({
  locationType,
  locationPackagesViewConfig,
  store,
  loading = true
}: IFetchLocationPackagesParams): Promise<IApiResponse> {
  if (loading) await store.dispatch("setAppLoading", true);

  const packages = await store.dispatch("fetchLocationPackages", {
    locationType: locationType.value,
    params: locationPackagesViewConfig
  });

  if (!packages.success && packages.message) {
    await store.dispatch("showErrorNotification", packages.message);
  }

  await store.dispatch("setAppLoading", false);

  return packages;
}
