import { ComputedRef, reactive, ref, Ref, watch } from "vue";
import {
  ILocationPackagesSort,
  locationPackagesFilterOptions,
  TPackageLocation
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { generateLocationPackagesRows } from "@/hooks/esim/location-packages/modules/generateLocationPackagesRows";
import { locationKeys } from "@/hooks/esim/location-packages/modules/locationKeys";
import { getLocationPackagesSortConfig } from "@/hooks/esim/location-packages/modules/getLocationPackagesSortConfig";
import { useBaseTable } from "@/hooks/tables/useTable";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { fetchLocationPackages } from "@/hooks/esim/location-packages/modules/fetchLocationPackages";
import { ILocationPackageListItem } from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import { sortOrders } from "@/enums/main/sortOrders";
import { useLocationPackagesList } from "@/hooks/esim/location-packages/modules/useLocationPackagesList";
import { IApiResponse } from "@/api/interfaces/IApi";
import { esimDirectoriesTypes } from "@/hooks/esim/directories/types/esimDirectories.types";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import { fetchEsimDirectories } from "@/hooks/esim/directories/modules/fetchEsimDirectories";

export interface IUseLocationPackages extends IUseBaseAppLayout, useBaseTable {
  pageTitle: ComputedRef<string>;
  initialSortOptions: ILocationPackagesSort;
  currentSortKeyLocationName: ComputedRef<string>;
  locationPackagesList: ComputedRef<ILocationPackageListItem[]>;
  fetchPackages: (loading?: boolean) => Promise<IApiResponse>;
  trafficAmountList: ComputedRef<IEsimDirectoryDefault[]>;
  totalRowsCount: ComputedRef<number>;
}

export function useLocationPackages(options: {
  locationType: Ref<TPackageLocation>;
}): IUseLocationPackages {
  const store = useStore();

  const locationPackagesModel = computed(() => {
    return store.getters.locationPackages(options.locationType.value);
  });

  const currentSortKeyLocationName = computed(() => {
    if (!options.locationType.value) {
      return "";
    }

    return locationKeys[options.locationType.value].single;
  });

  const initialSortOptions = getLocationPackagesSortConfig(
    currentSortKeyLocationName
  );

  const generateTableRows = generateLocationPackagesRows();

  const {
    filter,
    searchQuery,
    selectedFilterOption,
    onFilterSelect,
    searchHandler
  } = useBaseAppLayout({
    initialSearchQuery: "",
    filterOptions: [
      locationPackagesFilterOptions.all,
      locationPackagesFilterOptions.empty,
      locationPackagesFilterOptions.filled
    ]
  });

  const currentViewConfiguration = reactive({ page: 1, perPage: 10 });

  const requestOptions = reactive({
    ...currentViewConfiguration,
    search: searchQuery.value,
    filter:
      selectedFilterOption.value?.name || locationPackagesFilterOptions.all
  });

  const localeCurrentSort = ref({
    keyName: initialSortOptions.byLocationName.keyName,
    order: initialSortOptions.byLocationName.order
  });

  const {
    viewConfiguration,
    rows,
    currentSort,
    changeViewConfiguration,
    changeSorting,
    sortedModel
  } = useBaseTable({
    initialSortOptions: initialSortOptions,
    initialViewConfiguration: { page: 1, perPage: 10 },
    model: locationPackagesModel,
    generateRows: generateTableRows,
    onViewConfigurationChanged: ({ page, perPage }) => {
      if (requestOptions.perPage !== perPage) {
        requestOptions.page = 1;
      } else {
        requestOptions.page = page;
      }

      requestOptions.perPage = perPage;
    }
  });

  const locationPackagesList: ComputedRef<ILocationPackageListItem[]> = computed(
    () => {
      return useLocationPackagesList(sortedModel.value);
    }
  );

  async function fetchPackages(loading = true): Promise<IApiResponse> {
    return await fetchLocationPackages({
      locationType: options.locationType,
      locationPackagesViewConfig: requestOptions,
      store,
      loading
    });
  }

  watch(
    currentSort,
    sort => {
      const { keyName = "", order = sortOrders.turnOff } =
        Object.values(sort).find(({ order }) => order !== sortOrders.turnOff) ||
        {};

      localeCurrentSort.value = { keyName, order };
    },
    { immediate: true, deep: true }
  );

  watch(searchQuery, query => {
    requestOptions.search = query;
    changeViewConfiguration({
      page: 1,
      perPage: currentViewConfiguration.perPage
    });
  });

  watch(selectedFilterOption, selectedFilterOption => {
    changeViewConfiguration({
      page: 1,
      perPage: currentViewConfiguration.perPage
    });

    requestOptions.filter =
      selectedFilterOption?.name || locationPackagesFilterOptions.all;
  });

  watch(
    requestOptions,
    async () => {
      await fetchPackages();
    },
    { deep: true, immediate: true }
  );

  watch(options.locationType, async () => {
    changeViewConfiguration({ page: 1, perPage: 10 });
    await fetchPackages();
  });

  const pageTitle: ComputedRef<string> = computed(() => {
    if (!options.locationType.value) {
      return "";
    }

    const keyName = locationKeys[options.locationType.value].single;
    return `${keyName} Packages`;
  });

  fetchEsimDirectories({
    directoryType: esimDirectoriesTypes.trafficAmount,
    store
  });

  const trafficAmountList = computed(() =>
    store.getters.esimDirectoriesList(esimDirectoriesTypes.trafficAmount)
  );
  return {
    rows,
    filter,
    currentSort,
    searchQuery,
    sortedModel,
    selectedFilterOption,
    viewConfiguration,
    pageTitle,
    initialSortOptions,
    currentSortKeyLocationName,
    locationPackagesList,
    trafficAmountList,
    totalRowsCount: computed(() =>
      store.getters.totalLocationPackages(options.locationType.value)
    ),
    fetchPackages,
    onFilterSelect,
    searchHandler,
    changeViewConfiguration,
    changeSorting
  };
}
