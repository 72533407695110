import {
  ILocationPackage,
  ILocationPlan
} from "@/api/services/packages/esim/location-packages/types";
import {
  ILocationPackageListItem,
  ILocationPackageListItemPackage,
  IOptionsPlan
} from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import { TLocationName } from "@/hooks/esim/location-packages/modules/locationKeys";
import { getUpdatedPackages } from "@/hooks/esim/location-packages/modules/getUpdatedPackages";

export function useLocationPackagesList(
  locations: ILocationPackage[] = []
): ILocationPackageListItem[] {
  return locations.map(
    ({ name, id, countryPlans, subregionPlans, regionPlans, subregions }) => {
      const currentPlans = countryPlans || subregionPlans || regionPlans || [];
      const canExpended = !subregions?.length;
      const locationId: number = id;
      let currentLocationName: TLocationName;
      if (countryPlans) {
        currentLocationName = "country";
      } else if (regionPlans) {
        currentLocationName = "region";
      } else {
        currentLocationName = "subregion";
      }

      if (!canExpended) {
        return {
          id,
          name,
          canExpended,
          plans: [],
          currentLocationName
        };
      }

      return {
        id,
        canExpended: true,
        currentLocationName,
        name,
        plans: currentPlans.map(
          ({
            plan,
            discount,
            discountAmount,
            zone,
            planOperators = []
          }: ILocationPlan) => {
            const { name: planName, packages, id: planId } = plan;
            return {
              name: planName,
              id: planId,
              zone,
              planOperators,
              locationId,
              discount,
              currentLocationName,
              discountAmount,
              packages: getUpdatedPackages(
                packages.map(
                  ({
                    id,
                    validity,
                    title,
                    purchaseType,
                    dataType,
                    defaultPrice,
                    priceWithDiscount,
                    bundleId,
                    traffic,
                    commission,
                    providerType
                  }): ILocationPackageListItemPackage => {
                    return {
                      title,
                      description: validity?.title || "",
                      purchaseType: purchaseType?.title || "",
                      validity,
                      id,
                      providerType,
                      editData: {
                        currentZoneName: zone?.title || "",
                        currentZoneId: zone?.id || null,
                        packageCommission: commission || 0,
                        title,
                        planName,
                        bundleId,
                        dataType,
                        validity,
                        purchaseType,
                        defaultPrice,
                        discountAmount,
                        discount,
                        selectedPlan: plan,
                        priceWithDiscount,
                        locationId,
                        packageId: id,
                        traffic,
                        currentPlans: currentPlans.map(
                          ({ plan }): IOptionsPlan => {
                            return {
                              name: plan.name,
                              id: plan.id,
                              selected: planId === plan.id
                            };
                          }
                        ),
                        providerType
                      }
                    };
                  }
                )
              )
            };
          }
        )
      };
    }
  );
}
