
import { defineComponent, PropType } from "vue";
import { ILocationPackageListItemEditDto } from "@/hooks/esim/location-packages/types/useLocationPackageListType";

import Card from "@/components/ui/Card/Card.vue";
import SearchControl from "@/components/Form/SearchControl.vue";
import InlineAccordion from "@/components/ui/Accourdion/InlineAccordion.vue";
import EsimPackageCard from "@/components/Packages/EsimPackageCard.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";

import AppButton from "@/components/ui/Buttons/AppButton.vue";
import CustomScrollbar from "@/components/ui/CustomScrollbar.vue";

import { useListHeightTransition } from "@/hooks/animation/useListHeightTransition";
import { IUpdatedPackagesByPurchaseTypeAndValidity } from "@/hooks/esim/location-packages/modules/getUpdatedPackages";
import { IPurchaseType } from "@/api/services/packages/esim/location-packages/types";
import AppTabsHead from "@/components/AppTabs/AppTabsHead.vue";
import AppTabsBody from "@/components/AppTabs/AppTabsBody.vue";
export default defineComponent({
  name: "CountryPackageCard",
  components: {
    AppTabsBody,
    AppTabsHead,
    CustomScrollbar,
    AppButton,
    BtnContent,
    EsimPackageCard,
    InlineAccordion,
    SearchControl,
    Card
  },
  emits: ["editPackage", "addPackage", "deletePackage"],
  props: {
    title: { type: String, required: true },

    packages: {
      type: Array as PropType<IUpdatedPackagesByPurchaseTypeAndValidity[]>,
      required: true
    }
  },

  data() {
    return {
      selectedTab: 0,
      packageCategories: this.categories,
      activeCategoryName: "-1",
      searchQuery: "",
      transition: useListHeightTransition(0.15)
    };
  },
  //
  // mixin: [useTabs],

  computed: {
    packagesArray() {
      return this.packages.map(([purchaseType, validityTypes]) => {
        return [
          purchaseType,
          validityTypes
            .filter(({ packagesTitles, validityName }) => {
              if (!this.searchQuery.length) {
                return true;
              }

              return (
                validityName.toLowerCase().includes(this.searchQuery) ||
                packagesTitles.some(title => {
                  return title.includes(this.searchQuery);
                })
              );
            })
            .map(validityCategory => {
              return {
                ...validityCategory,
                opened:
                  validityCategory.validityName === this.activeCategoryName
              };
            })
        ];
      });
    },

    tabs() {
      return this.$store.getters.purchaseTypes
        .filter(({ active }: IPurchaseType) => active)
        .map(({ title }: IPurchaseType) => title);
    },
    getEmptyData() {
      const data = this.packagesArray.find(el => {
        if (el[0] === this.tabs[this.selectedTab]) {
          return !!el[1].length;
        } else {
          return false;
        }
      });
      return !data ?? false;
    }
  },

  watch: {
    tabs: {
      deep: true,
      immediate: true,
      handler(tabs) {
        this.selectedTab = 0;
      }
    }
  },

  methods: {
    packageAdd(data: ILocationPackageListItemEditDto) {
      this.$emit("addPackage", data);
    },

    packageEdit(data: ILocationPackageListItemEditDto) {
      this.$emit("editPackage", data);
    },

    packageDelete(id: number) {
      this.$emit("deletePackage", id);
    },

    packageCategoriesAccordionHandler(validityName: string) {
      this.activeCategoryName = validityName;
    },

    searchHandler(query: string) {
      this.searchQuery = query.toLowerCase().trim();
    }
  }
});
